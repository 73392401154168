import { Controller } from '@hotwired/stimulus';
import GLightbox from 'glightbox';

// Connects to data-controller="lightbox"
export default class extends Controller {
  connect() {
    GLightbox({
      selector: '.lightbox',
    });
  }
}
