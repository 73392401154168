import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="game-demo"
export default class extends Controller {
  static targets = ['iframe', 'cover'];

  static values = {
    link: String,
  };

  play() {
    this.iframeTarget.src = this.linkValue;
    this.coverTarget.style.display = 'none';
  }
}
